export default {
    data() {
        return {
            exp_data: null,
            notifications: []
        }
    },
    created() {
        this.getNotifications();
        this.exp_data = this.$store.state.auth.exp_data;
    },
    methods: {
        getNotifications() {
            this.$http.get('/get-notifications')
                .then(response => {
                    if (response.data.status && response.status == 200) {
                        this.notifications = response.data.notifications
                        console.log(this.notifications)
                    }

                }).catch((err) => {
                    console.log(err)
                })
        },
        clearAll() {
            this.$http.get('/clear-notifications')
                .then(response => {
                    if (response.data.status && response.status == 200) {
                        this.notifications = [];
                        if (localStorage.getItem('token')) {
            this.$store.dispatch('auth/attempt', localStorage.getItem('token'))
        }
                    }
                }).catch((err) => {
                    console.error(err);
                    this.$bvToast.toast('Oops! Something went wrong', {
                        title: `Error`,
                        variant: `danger`,
                        solid: true,
                    })
                })
        },
        goToEventDetails(index) {
            if (this.notifications) {
                let object_id = this.notifications[index].notification_payload.payload.object_id;
                let object_type = this.notifications[index].notification_payload.payload.object_type;
                for (let i in this.exp_data[object_type]) {
                    if (this.exp_data[object_type][i].occurence_id == object_id) {
                        if (!this.notifications[index].is_read) {
                            this.$http.post('/notification-read-state', {
                                notification_id: this.notifications[index].id
                            })
                                .then(() => {
                                    this.$store.dispatch('auth/attempt', this.$store.state.token);
                                }).catch((err) => {
                                    console.log(err)
                                })
                        }
                        this.$router.push({ path: `/experiences/${object_type}/${this.exp_data[object_type][i].id}` })
                    }
                }
            }
        }
    }

}
